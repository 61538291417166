<template>
  <div class="with-nav-bar">
    <van-nav-bar
      left-text="返回"
      :right-text="state.has_set ? '同步' : ''"
      title="费用设置"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
      @click-right="
        state.has_set &&
          $router.push({
            name: 'Syncfee',
            query: { station_id: state.station_id },
          })
      "
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <van-tabs v-model:active="state.rate_type" color="#1989fa">
        <van-tab title="固定费率">
          <van-field
            v-model="state.service_rate"
            type="number"
            label="服务单价"
            @blur="formatrate('service_rate')"
          >
            <template #extra>元/度</template>
          </van-field>
          <van-field
            v-model="state.fixed_rate"
            type="number"
            label="电费单价"
            :error-message="
              state.fixed_rate < state.fixed_rate_cost ? '不可小于成本价' : ''
            "
            @blur="formatrate('fixed_rate')"
          >
            <template #extra>元/度</template>
          </van-field>
          <van-field
            v-model="state.fixed_rate_cost"
            type="number"
            label="电费成本"
            @blur="formatrate('fixed_rate_cost')"
          >
            <template #extra>元/度</template>
          </van-field>
        </van-tab>
        <van-tab title="浮动费率">
          <van-field
            v-model="state.service_rate"
            type="number"
            label="服务单价"
            @blur="formatrate('service_rate')"
          >
            <template #extra>元/度</template>
          </van-field>
          <van-row
            v-for="(item, index) in floatingSettings"
            :key="index"
            class="van-hairline--bottom"
          >
            <van-col span="14">
              <van-field
                v-model="item.power"
                type="number"
                placeholder="元/度"
                :label="`${item.begin}~${item.end}`"
                @blur="formatFloatingSettings(index, 'power')"
              />
            </van-col>
            <van-col span="10"
              ><van-field
                v-model="item.cost"
                type="number"
                placeholder="元/度"
                label="成本"
                :colon="true"
                :label-width="40"
                @blur="formatFloatingSettings(index, 'cost')"
            /></van-col>
          </van-row>
        </van-tab>
      </van-tabs>

      <div class="py-3 action-button-area text-center">
        <p
          v-if="state.rate_type == 0"
          :class="profitRate > 0 ? 'text-success' : 'text-danger'"
        >
          当前利润率：{{ profitRate }}元/度
        </p>
        <van-button v-if="!canSubmit" type="primary" disabled
          >保存设置</van-button
        >
        <van-button v-else type="primary" @click="submitForm"
          >保存设置</van-button
        >
        <van-button v-if="state.rate_type == 1" @click="resetFloatingSettings"
          >重置时段</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from "vue";
import { Field, Toast, Tab, Tabs, Divider, Col, Row } from "vant";
import { useRoute, useRouter } from "vue-router";
import { getFeeSettings, updateFeeSettings } from "@/api/station.service";
export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Divider.name]: Divider,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      station_id: 0,
      rate_type: 0, // 0 固定, 1 浮动, 对应 tabs
      service_rate: 0,
      fixed_rate: 0,
      fixed_rate_cost: 0,
      floating_settings: "",
      has_set: false, // 是否已设置过, 后端有记录的情况下为 true, 可以查看设备同步费率的情况
      loading: true,
    });
    const floatingSettings = reactive([]);
    const resetFloatingSettings = () => {
      floatingSettings.length = 0;
      floatingSettings.push(
        {
          begin: "00:00",
          end: "07:00",
          power: "0.00",
          cost: "0.00",
          service: "",
        },
        {
          begin: "07:00",
          end: "10:00",
          power: "0.00",
          cost: "0.00",
          service: "",
        },
        {
          begin: "10:00",
          end: "12:00",
          power: "0.00",
          cost: "0.00",
          service: "",
        },
        {
          begin: "12:00",
          end: "16:00",
          power: "0.00",
          cost: "0.00",
          service: "",
        },
        {
          begin: "16:00",
          end: "22:00",
          power: "0.00",
          cost: "0.00",
          service: "",
        },
        {
          begin: "22:00",
          end: "23:00",
          power: "0.00",
          cost: "0.00",
          service: "",
        },
        {
          begin: "23:00",
          end: "24:00",
          power: "0.00",
          cost: "0.00",
          service: "",
        }
      );
    };

    const loadForm = async () => {
      try {
        const { result } = await getFeeSettings(state.station_id);
        state.rate_type = result.rate_type;
        state.service_rate = new Number(result.service_rate / 100).toFixed(2);
        state.fixed_rate = new Number(result.fixed_rate / 100).toFixed(2);
        state.fixed_rate_cost = new Number(
          result.fixed_rate_cost / 100
        ).toFixed(2);
        if (result.rate_type == 1) {
          const tmp = JSON.parse(result.floating_settings);
          if (tmp && tmp.length > 0) {
            floatingSettings.length = 0;
            floatingSettings.push(...tmp);
          }
        }
        state.loading = false;
        state.has_set = true;
      } catch (error) {
        state.loading = false;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const canSubmit = computed(() => {
      if (
        state.service_rate <= 0 ||
        (state.rate_type == 0 && state.fixed_rate <= 0) ||
        (state.rate_type == 0 && state.fixed_rate_cost <= 0) ||
        state.fixed_rate < state.fixed_rate_cost
      ) {
        return false;
      }
      return true;
    });

    const profitRate = computed(() => {
      return new Number(
        parseFloat(state.service_rate) +
          parseFloat(state.fixed_rate) -
          parseFloat(state.fixed_rate_cost)
      ).toFixed(2);
    });

    const submitForm = async () => {
      try {
        let data = {
          station_id: state.station_id,
          rate_type: state.rate_type,
          service_rate: state.service_rate * 100,
        };
        if (state.rate_type == 0) {
          data.fixed_rate = state.fixed_rate * 100;
          data.fixed_rate_cost = state.fixed_rate_cost * 100;
          data.floating_settings = "";
        } else {
          floatingSettings[0].service = state.service_rate;
          data.fixed_rate = 0;
          data.fixed_rate_cost = 0;
          data.floating_settings = JSON.stringify(floatingSettings);
        }
        await updateFeeSettings(data);
        Toast.success("保存成功");
        state.has_set = true;
        router.push({
          name: "Syncfee",
          query: { station_id: state.station_id },
        });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const formatrate = (field) => {
      state[field] = new Number(state[field]).toFixed(2);
    };
    const formatFloatingSettings = (index, field) => {
      floatingSettings[index][field] = new Number(
        floatingSettings[index][field]
      ).toFixed(2);
    };

    onMounted(() => {
      const { station_id } = route.query;
      if (!station_id) {
        Toast.fail("参数错误");
        router.back();
      } else {
        resetFloatingSettings();
        state.station_id = station_id;
        loadForm(station_id);
      }
    });

    return {
      state,
      floatingSettings,
      canSubmit,
      profitRate,
      submitForm,
      formatrate,
      formatFloatingSettings,
      resetFloatingSettings,
    };
  },
};
</script>
